import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the VintageFlavourizer Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="vintage-flavourizer" to your markup alongside
   * data-reflex="VintageFlavourizer#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->VintageFlavourizer#dance" data-controller="vintage-flavourizer">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "VintageFlavourizer#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // beforeFind(element, reflex, noop, reflexId) {
  //  console.log("before find", element, reflex, reflexId)
  // }

  // findSuccess(element, reflex, noop, reflexId) {
  //   console.log("find success", element, reflex, reflexId)
  // }

  // findError(element, reflex, error, reflexId) {
  //   console.error("find error", element, reflex, error, reflexId)
  // }

  // findHalted(element, reflex, noop, reflexId) {
  //   console.warn("find halted", element, reflex, reflexId)
  // }

  // afterFind(element, reflex, noop, reflexId) {
  //   console.log("after find", element, reflex, reflexId)
  // }

  // finalizeFind(element, reflex, noop, reflexId) {
  //   console.log("finalize find", element, reflex, reflexId)
  // }

  // beforeAdd(element, reflex, noop, reflexId) {
  //  console.log("before add", element, reflex, reflexId)
  // }

  // addSuccess(element, reflex, noop, reflexId) {
  //   console.log("add success", element, reflex, reflexId)
  // }

  // addError(element, reflex, error, reflexId) {
  //   console.error("add error", element, reflex, error, reflexId)
  // }

  // addHalted(element, reflex, noop, reflexId) {
  //   console.warn("add halted", element, reflex, reflexId)
  // }

  // afterAdd(element, reflex, noop, reflexId) {
  //   console.log("after add", element, reflex, reflexId)
  // }

  // finalizeAdd(element, reflex, noop, reflexId) {
  //   console.log("finalize add", element, reflex, reflexId)
  // }

  // beforeRemove(element, reflex, noop, reflexId) {
  //  console.log("before remove", element, reflex, reflexId)
  // }

  // removeSuccess(element, reflex, noop, reflexId) {
  //   console.log("remove success", element, reflex, reflexId)
  // }

  // removeError(element, reflex, error, reflexId) {
  //   console.error("remove error", element, reflex, error, reflexId)
  // }

  // removeHalted(element, reflex, noop, reflexId) {
  //   console.warn("remove halted", element, reflex, reflexId)
  // }

  // afterRemove(element, reflex, noop, reflexId) {
  //   console.log("after remove", element, reflex, reflexId)
  // }

  // finalizeRemove(element, reflex, noop, reflexId) {
  //   console.log("finalize remove", element, reflex, reflexId)
  // }
}
