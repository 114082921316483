import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["location"];

  toggleLocation(event) {
    event.preventDefault();
    this.locationTarget.classList.toggle("hidden");
  }
}
