import ApplicationController from './application_controller.js'

export default class extends ApplicationController {

  connect() {
    console.log("connected")
  }

  trigger() {
    console.log("triggered")
  }

}
