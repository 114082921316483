import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the RegionGuideManager Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="region-guide-manager" to your markup alongside
   * data-reflex="RegionGuideManager#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->RegionGuideManager#dance" data-controller="region-guide-manager">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "RegionGuideManager#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // beforeCreate(element, reflex, noop, reflexId) {
  //  console.log("before create", element, reflex, reflexId)
  // }

  // createSuccess(element, reflex, noop, reflexId) {
  //   console.log("create success", element, reflex, reflexId)
  // }

  // createError(element, reflex, error, reflexId) {
  //   console.error("create error", element, reflex, error, reflexId)
  // }

  // createHalted(element, reflex, noop, reflexId) {
  //   console.warn("create halted", element, reflex, reflexId)
  // }

  // afterCreate(element, reflex, noop, reflexId) {
  //   console.log("after create", element, reflex, reflexId)
  // }

  // finalizeCreate(element, reflex, noop, reflexId) {
  //   console.log("finalize create", element, reflex, reflexId)
  // }

  // beforeUpdate(element, reflex, noop, reflexId) {
  //  console.log("before update", element, reflex, reflexId)
  // }

  // updateSuccess(element, reflex, noop, reflexId) {
  //   console.log("update success", element, reflex, reflexId)
  // }

  // updateError(element, reflex, error, reflexId) {
  //   console.error("update error", element, reflex, error, reflexId)
  // }

  // updateHalted(element, reflex, noop, reflexId) {
  //   console.warn("update halted", element, reflex, reflexId)
  // }

  // afterUpdate(element, reflex, noop, reflexId) {
  //   console.log("after update", element, reflex, reflexId)
  // }

  // finalizeUpdate(element, reflex, noop, reflexId) {
  //   console.log("finalize update", element, reflex, reflexId)
  // }

  // beforeDeploy(element, reflex, noop, reflexId) {
  //  console.log("before deploy", element, reflex, reflexId)
  // }

  // deploySuccess(element, reflex, noop, reflexId) {
  //   console.log("deploy success", element, reflex, reflexId)
  // }

  // deployError(element, reflex, error, reflexId) {
  //   console.error("deploy error", element, reflex, error, reflexId)
  // }

  // deployHalted(element, reflex, noop, reflexId) {
  //   console.warn("deploy halted", element, reflex, reflexId)
  // }

  // afterDeploy(element, reflex, noop, reflexId) {
  //   console.log("after deploy", element, reflex, reflexId)
  // }

  // finalizeDeploy(element, reflex, noop, reflexId) {
  //   console.log("finalize deploy", element, reflex, reflexId)
  // }
}
