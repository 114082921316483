import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the ShipmentManager Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="shipment-manager" to your markup alongside
   * data-reflex="ShipmentManager#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->ShipmentManager#dance" data-controller="shipment-manager">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ShipmentManager#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // beforeAddWine(element, reflex, noop, reflexId) {
  //  console.log("before add_wine", element, reflex, reflexId)
  // }

  // addWineSuccess(element, reflex, noop, reflexId) {
  //   console.log("add_wine success", element, reflex, reflexId)
  // }

  // addWineError(element, reflex, error, reflexId) {
  //   console.error("add_wine error", element, reflex, error, reflexId)
  // }

  // addWineHalted(element, reflex, noop, reflexId) {
  //   console.warn("add_wine halted", element, reflex, reflexId)
  // }

  // afterAddWine(element, reflex, noop, reflexId) {
  //   console.log("after add_wine", element, reflex, reflexId)
  // }

  // finalizeAddWine(element, reflex, noop, reflexId) {
  //   console.log("finalize add_wine", element, reflex, reflexId)
  // }

  // beforeRemoveWine(element, reflex, noop, reflexId) {
  //  console.log("before remove_wine", element, reflex, reflexId)
  // }

  // removeWineSuccess(element, reflex, noop, reflexId) {
  //   console.log("remove_wine success", element, reflex, reflexId)
  // }

  // removeWineError(element, reflex, error, reflexId) {
  //   console.error("remove_wine error", element, reflex, error, reflexId)
  // }

  // removeWineHalted(element, reflex, noop, reflexId) {
  //   console.warn("remove_wine halted", element, reflex, reflexId)
  // }

  // afterRemoveWine(element, reflex, noop, reflexId) {
  //   console.log("after remove_wine", element, reflex, reflexId)
  // }

  // finalizeRemoveWine(element, reflex, noop, reflexId) {
  //   console.log("finalize remove_wine", element, reflex, reflexId)
  // }

  // beforePublish(element, reflex, noop, reflexId) {
  //  console.log("before publish", element, reflex, reflexId)
  // }

  // publishSuccess(element, reflex, noop, reflexId) {
  //   console.log("publish success", element, reflex, reflexId)
  // }

  // publishError(element, reflex, error, reflexId) {
  //   console.error("publish error", element, reflex, error, reflexId)
  // }

  // publishHalted(element, reflex, noop, reflexId) {
  //   console.warn("publish halted", element, reflex, reflexId)
  // }

  // afterPublish(element, reflex, noop, reflexId) {
  //   console.log("after publish", element, reflex, reflexId)
  // }

  // finalizePublish(element, reflex, noop, reflexId) {
  //   console.log("finalize publish", element, reflex, reflexId)
  // }
}
